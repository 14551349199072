body{
  font-size: 14px;
}
.entry-title {
  text-decoration: none;
}
.footer {
  font-size: 15px;
}
.theme_button {
  text-decoration: none;
}

.carousel  img {
opacity: .2;
}

.carousel-item {
  text-align: center !important;
}
.carousel.caption {
  margin: 0;
  border: 0;
  padding: 0;
  bottom:220px;
  text-align: center !important;
}

.carousel-caption h6 {
  color: white;
  font-size: 30px;
  text-transform: uppercase;
   margin-top: 25px;
 font-weight: 400;
}

@media only screen and (max-width: 1020px) {
  .carousel-caption h6 {
    font-size: 25px;
    font-weight: 350;
  }

  
}

@media only screen and (max-width: 800px) {
  .carousel-caption h6 {
    font-size: 20px;
    font-weight: 300;
  }
}

@media only screen and (max-width: 650px) {
  .carousel-caption h6 {
    font-size: 15px;
    font-weight: 250;
  }
}

@media only screen and (max-width: 580px) {
  .carousel-caption h6 {
    font-size: 12px;
    font-weight: 200;
  }
}

@media only screen and (max-width: 320px) {
  .carousel-caption h6 {
    font-size: 10px;
    font-weight: 175;
  }
}

.carousel-caption p em {
  color: white;
  font-size: 22px;
  margin: auto;
  width: 60%;
  line-height: 1.9;
}

@media only screen and (max-width: 1020px) {
  .carousel-caption p em {
      font-size: 14px;
  }
}

@media only screen and (max-width: 800px) {
  .carousel-caption p em {
      font-size: 12px;
  }
}
@media only screen and (max-width: 650px) {
  .carousel-caption p em {
      font-size: 12px;
  }
}

@media only screen and (max-width: 400px) {
  .carousel-caption p em {
      font-size: 7px;
  }
}

@media only screen and (max-width: 300px) {
  .carousel-caption p em {
     font-size: 5px;
  }
}

.carousel-caption h2 strong {
  font-size: 80px;
  text-transform: uppercase;
  margin-bottom: 0px;
  font-weight: 900;
}

@media only screen and (max-width: 1020px) {
  .carousel-caption h2 strong {
      font-size: 60px;
      font-weight: 800;
  }
}

@media only screen and (max-width: 800px) {
  .carousel-caption h2 strong {
      font-size: 50px;
      font-weight: 700;
  }
}

@media only screen and (max-width: 650px) {
  .carousel-caption h2 strong {
      font-size: 40px;
      font-weight: 500;
  }
}

@media only screen and (max-width: 575px) {
  .carousel-caption h2 strong {
      font-size: 30px;
      font-weight: 400;
     
  }
}

@media only screen and (max-width: 500px) {
  .carousel-caption h2 strong {
      font-size: 25px;
      font-weight: 400;
  }
}

@media only screen and (max-width: 380px) {
  .carousel-caption h2 strong {
      font-size: 15px;
      font-weight: 500;
  }
}

.carousel-caption h2 {
  color: white;
  font-size: 80px;
  text-transform: uppercase;
  margin-bottom: 0px;
  list-style: .8;
}

@media only screen and (max-width: 1020px) {
  .carousel-caption h2  {
      font-size: 60px;
  }
}

@media only screen and (max-width: 800px) {
  .carousel-caption h2  {
      font-size: 50px;
  }
}

@media only screen and (max-width: 650px) {
  .carousel-caption h2  {
      font-size: 40px;
  }
}

@media only screen and (max-width: 575px) {
  .carousel-caption h2  {
      font-size: 30px;
      
  }
}

@media only screen and (max-width: 500px) {
  .carousel-caption h2  {
      font-size: 25px;
  }
  
}

@media only screen and (max-width: 380px) {
  .carousel-caption h2  {
      font-size: 15px;
  }
  
}

@media only screen and (max-width: 280px) {
  .carousel-caption h2 {
      font-size: 12px;
  }
}

.ls h1, .ls h2, .ls h3, .ls h4, .ls h5, .ls h6 {
  color: black;
}

.ds .highlight {
  color: #4163ab;
}

.ds .highlight1 {
  color: #89adf9;
}

.cs {
  background-color: #6d7071;
}

.cs.main_color3 {
  background-color: #b5b4a7;
}
.theme_button.color2 a, .theme_button.color2 {
  background-color: #4163ab;
  border-color: #4163ab;
}
.ls .highlight2 {
  color: #4163ab;
  border-color: #4163ab;
}


a {
  text-decoration: none !important;
}

@media only screen and (max-width: 550px) {
.ds .intro-layer .theme_button {
display: none;
}
}

.nav {
  display: block !important;
}
@media only screen and (max-width: 991px) {
  .sf-menu {
    text-align: center !important;
  }
}

@media only screen and (min-width: 992px) {
  .sf-menu>li>a {
      padding-right: 15px !important;
  }
}

input[type=text], input[type=email], input[type=tel], 
input[type=number], .form-control  {
  height: 40px;
  border: 2px solid #d6d6dd;
  padding-left: 10px;
  font-size: 16px;
}
textarea.form-control {
  font-size: 16px;
  padding-left: 10px;
  border: 2px solid #d6d6d6;
}
.btn-group-lg>.btn, .btn-lg {
  font-weight: 600 !important;
  border-width: 2px !important;
}
.ms-auto {
  font-size: 16px;
  font-weight: 700;
  margin-left: 15px;
  margin-right: 15px;
  text-transform: uppercase;
}
.navbar-light .navbar-nav .nav-link {
  color: black !important;
  text-align: center !important;
}
.navbar-nav .nav-link {
  margin-left: 1.3rem;
  margin-right: 1.3rem;
}

.section_header+.fontsize_20 {
  text-align: left !important;
}

.form-group p {
  color: red;
  font-family: Arial, Helvetica, sans-serif;
}
.grid {
  min-height: 100%;
  display: flex;
  flex-wrap: wrap;
  flex-direction: row;
}

.box {
  margin: 20px;
  margin-left: 50px;
}
@media only screen and (max-width: 1024px) and (min-width: 1000px)  {
.box {
  margin: 20px;
  margin-left: 100px;
}
}

@media only screen and (max-width: 780px) and (min-width: 760px) {
  .box {
    margin: 20px;
    margin-left: 200px;
  }
  }

.card  {
  text-align: center;
}

.card h1 {
  font-size: 23px;
}
.margin_0 {
  margin: 10px !important;
} 
.modal-title {
  font-size: 25px;
}
.btn-outline-success {
  margin-top: 1rem !important;
  margin-bottom: 1rem !important;
}
.contact-errors {
  color: red;
}

/* for desktop */
.whatsapp_float {
	position:fixed;
	width:60px;
	height:60px;
	bottom:40px;
	right:40px;
	background-color:#25d366;
	color:#FFF;
	border-radius:50px;
	text-align:center;
        font-size:30px;
	box-shadow: 2px 2px 3px #999;
        z-index:100;
}

.whatsapp-icon {
	margin-top:16px;
}
/* for mobile */
@media screen and (max-width: 767px){
     .whatsapp-icon {
	 margin-top:10px;
     }
    .whatsapp_float {
        width: 40px;
        height: 40px;
        bottom: 20px;
        right: 40px;
        font-size: 22px;
    }
}

.btn-outline-success:disabled, .btn-outline-success:disabled {
  padding: 12px 20px;
  font-size: 13px;
}

.button:not(:disabled), [type=button]:not(:disabled),
[type=reset]:not(:disabled), [type=submit]:not(:disabled) {
  padding: 12px 20px !important;
  font-size: 13px;
}
.topmargin_10 {
  margin-top: 0px !important;
}

.modal {
  background: rgba(0,0,0,0.8);
  }

.whyus {
  text-align: justify !important;
}

.fontsize_20 {
  font-size: 18px;
  line-height: 23px;
  text-align: justify;
}
.aboutus {
  margin-bottom: 20px;
}
@media only screen and (max-width: 980px) and (min-width: 750px) {
  .fontsize_20  {
      font-size: 10px !important;
  }
}
.brochure {
  padding-right: 2.0rem;
}
.btn-brochure {
  background-color: white; 
  border: none;
  padding: 1px 20px;
  text-align: center;
  text-decoration: none;
  display: inline-block;
  font-size: 16px;
  font-weight: 500 ;
}
/* @media only screen and (max-width: 780px) and (min-width: 700px) {
  .btn-brochure{
      
  }
} */
.mandatory {
  color: red;
}
.section_padding_15>[class*=container] {
  padding-top: 7px;
  padding-bottom: 7px;
}
.lnk {
  color: #cc0a0a;
}
.lnk:hover {
  color: white;
}
a.color-icon.soc-facebook {
  padding-bottom: 9px;
  color: #4163ab;
}
a.color-icon.soc-linkedin {
    padding-bottom: 9px;
}
.fa.fa-download {
  color: #4163ab;
}
.fa.fa-download:hover {
  color: black;
}